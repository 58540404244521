// *******************************************
// ************* Height Matching *************
// *******************************************

var heightMatch = (function ($) {
    
    // .height-match-wrapper = parent container
    // .height-match-cards = parent container for bootstrap cards
    // .height-match-item = child element to be height-matched using 'min-height'
    // .height-match-ignore = child element to be ignored

    var wrappers = [],
        active = false,
        pageLoaded = false;


    var init = function() {
        // general height-matching
        $('.height-match-wrapper').each(function(x, thisWrapper) {
            var i = wrappers.length;
            active = true;
            wrappers[i] = new Object({
                type : 'general',
                obj : thisWrapper,
                objWidth : 0,
                items : $(thisWrapper).find('.height-match-item:not(.height-match-ignore)')
            });
            wrappers[i].active = wrappers[i].items.length > 0 ? true : false;
        });
        // card height matching
        $('.height-match-cards').each(function(x, thisWrapper) {
            var i = wrappers.length;
            active = true;
            wrappers[i] = new Object({
                type : 'cards',
                obj : thisWrapper,
                objWidth : 0,
                items : $(thisWrapper).find('.card:not(.height-match-ignore)')
            });
            wrappers[i].active = wrappers[i].items.length > 0 ? true : false;
        });
        if(active) {
            $(window).on('load', function () {
                pageLoaded = true;
                heightMatch.update(false);
            });
            $(window).on('resize', function () {
                window.requestAnimationFrame(function() {
                    heightMatch.update(false);
                });
            });
        }
    };

    var update = function(force) { // bool force will run even if wrapper width hasn't changed
        if(active && pageLoaded) {
            for(var i=0; i < wrappers.length; i++) {
                if(wrappers[i].active) {
                    var wrapperWidth = $(wrappers[i].obj).width();
                    if(wrappers[i].objWidth != wrapperWidth || force) {
                        var hm_StartIndex = 0, 
                            hm_Top = 0;
                        if(wrappers[i].type == 'general') {
                            // reset heights
                            $(wrappers[i].items).css({'min-height' : 'auto'});
                            // calc new heights
                            var hm_Height = 0;
                            for(var x=0; x < wrappers[i].items.length; x++) {
                                var getTop = Math.floor($(wrappers[i].items[x]).offset().top);
                                if(getTop > hm_Top) {
                                    hm_Top = getTop;
                                    if(x > 0) {
                                        for(var z = hm_StartIndex; z < x; z++) {
                                            $(wrappers[i].items[z]).css({'min-height' : hm_Height + 'px'});
                                        }
                                        hm_Height = 0;
                                        hm_StartIndex = x;
                                    }
                                    hm_Top = Math.floor($(wrappers[i].items[x]).offset().top);
                                }
                                var getHeight = Math.ceil($(wrappers[i].items[x]).outerHeight());
                                if(getHeight > hm_Height) hm_Height = getHeight;
                                // last item?
                                if(x === (wrappers[i].items.length -1)) {
                                    for(z = hm_StartIndex; z < wrappers[i].items.length; z++) {
                                        $(wrappers[i].items[z]).css({'min-height' : hm_Height + 'px'});
                                    }
                                }
                            }
                        } else if(wrappers[i].type == 'cards') {
                            var hm_headerHeight = 0,
                                hm_bodyHeight = 0,
                                hm_footerHeight = 0,
                                getHeaderHeight = 0,
                                getBodyHeight = 0,
                                getFooterHeight = 0;
                            // reset heights
                            for(x=0; x < wrappers[i].items.length; x++) $(wrappers[i].items[x]).find('.card-header, .card-body, .card-footer').css({'min-height' : 'auto'});
                            // calc new heights
                            for(x=0; x < wrappers[i].items.length; x++) {
                                getTop = Math.floor($(wrappers[i].items[x]).offset().top);
                                if(getTop > hm_Top) {
                                    hm_Top = getTop;
                                    if(x > 0) {
                                        for(z = hm_StartIndex; z < x; z++) {
                                            $(wrappers[i].items[z]).find('.card-header').css({'min-height' : hm_headerHeight + 'px'});
                                            $(wrappers[i].items[z]).find('.card-body').css({'min-height' : hm_bodyHeight + 'px'});
                                            $(wrappers[i].items[z]).find('.card-footer').css({'min-height' : hm_footerHeight + 'px'});
                                        }
                                        hm_headerHeight = 0;
                                        hm_bodyHeight = 0;
                                        hm_footerHeight = 0;
                                        hm_StartIndex = x;
                                    }
                                    hm_Top = Math.floor($(wrappers[i].items[x]).offset().top);
                                }
                                getHeaderHeight = Math.ceil($(wrappers[i].items[x]).find('.card-header').outerHeight());
                                if(getHeaderHeight > hm_headerHeight) hm_headerHeight = getHeaderHeight;
                                getBodyHeight = Math.ceil($(wrappers[i].items[x]).find('.card-body').outerHeight());
                                if(getBodyHeight > hm_bodyHeight) hm_bodyHeight = getBodyHeight;
                                getFooterHeight = Math.ceil($(wrappers[i].items[x]).find('.card-footer').outerHeight());
                                if(getFooterHeight > hm_footerHeight) hm_footerHeight = getFooterHeight;
                                // last item?
                                if(x === (wrappers[i].items.length -1)) {
                                    for(z = hm_StartIndex; z < wrappers[i].items.length; z++) {
                                        $(wrappers[i].items[z]).find('.card-header').css({'min-height' : hm_headerHeight + 'px'});
                                        $(wrappers[i].items[z]).find('.card-body').css({'min-height' : hm_bodyHeight + 'px'});
                                        $(wrappers[i].items[z]).find('.card-footer').css({'min-height' : hm_footerHeight + 'px'});
                                    }
                                }
                            }
                        }

                        // store width
                        wrappers[i].objWidth = wrapperWidth;
                    }
                }
            }
        }
    };

    // public
    return {
        init: init,
        update: update
    };

})(jQuery);

$(document).ready(function () {
    heightMatch.init();
});