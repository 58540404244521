// *************************************
// *****  Basket modal *****
// *************************************
/* global siteSettings */
/* global accesso */
/* exported  basketModal */
var basketModal = (function ($) {

    var cookieName = 'LD_basket_counter';
    var stopModalCookieName = 'LD_stop_modal';
    var $basketModal = $('#basket-popup');

    var date = new Date();
    var minutes = 30;

    date.setTime(date.getTime() + (minutes * 60 * 1000));

    var settings = {},
        debug = false;

    var $basketNum = $('[data-accesso-quantity]');
    var basketQuantity = 0;
    var stop = false;

    function init() {
        var modalBlocked = jQuery.cookie(stopModalCookieName) !== undefined,
            modalDisabled = typeof siteSettings.modalSettings === 'undefined';

        if (modalBlocked || modalDisabled) {
            logDebugMessage('disabled or blocked ...');

            return;
        }
        settings = siteSettings;

        bindEvents();
    }

    function bindEvents() {
        $basketNum.on('DOMSubtreeModified', function () {
            basketQuantity = $basketNum.text();
            handleBasketChange();
        });

        $basketModal.on('touchend click', function () {
            hideModal();
            resetCounter();
        });
    }

    function handleBasketChange() {
        if (basketQuantity > 0 && !stop) {
            stop = true;

            registerPageView();

            if (jQuery.cookie(cookieName) > settings.modalSettings.pageViews) {
                showModal();
            }
        }
    }

    function registerPageView() {
        if (jQuery.cookie(cookieName) === undefined) {
            jQuery.cookie(cookieName, 1, { path: '/' });
        } else {
            var cookieValue = jQuery.cookie(cookieName);

            cookieValue = parseInt(cookieValue);
            cookieValue++;

            jQuery.cookie(cookieName, cookieValue, { path: '/' });
        }
    }

    function showModal() {
        if (jQuery.cookie(cookieName) > settings.modalSettings.pageViews && (basketQuantity !== 0 || basketQuantity !== '')) {
            $basketModal.append('<a class="basket- close">X</a>');
            $basketModal.append('<h2>' + settings.translate.modalTitle + '</h2>');
            $basketModal.append('<p>' + settings.translate.modalText + '</p>');
            $basketModal.append('<p><strong>' + settings.translate.tickets + ': ' + basketQuantity + '</strong></p>');
            $basketModal.append('<p><strong>' + settings.translate.total + ': ' + settings.translate.currency + '<span data-accesso-total></span></strong></p>');
            $basketModal.append('<a id="basket-reminder-button" href="#" class="btn btn-primary btn-lg ripped-button-wide" data-accesso-launch="checkout" title="Shopping Cart">' + settings.translate.modalCTA + '</a>');
            $basketModal.show();

            accesso.reinitializeHooks();
            // commented out events for Google Analytics dataLayer
            //            dataLayer.push({
            //                'reminderModal': 'launched',
            //                'event': 'basketReminderModalLaunch'
            //            });

        }

        setTimeout(function () {
            hideModal();
        }, settings.modalSettings.timeShown);
    }

    function hideModal() {
        $basketModal.fadeOut(function () {
            $basketModal.html('');
        });
    }

    function resetCounter() {
        jQuery.cookie(cookieName, 1, { path: '/' });
    }

    function logDebugMessage(message) {
        if (debug) {
            // eslint-disable-next-line no-console
            console.log(message);
        }
    }

    return {
        init: init
    };
})(jQuery);

$(document).ready(function () {
    basketModal.init($);
});