/* global Bloodhound */

(function ($) {

    var pageList = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            wildcard: '%QUERY',
            url: '/Umbraco/api/SearchApi/Search/?SearchText=%QUERY&CurrentSite=' + $('#header-search').data('site')
        }
    });

    $('#header-search .form-control').typeahead({
        hint: true,
        highlight: true,
        minLength: 1
    },
    {
        name: 'search-terms',
        source: pageList,
        displayKey: 'Name',
        templates: {
            empty: [
                '<div class="empty-message p-2">',
                '<p class="text-secondary">' + $('#header-search').data('no-results') + '</p>',
                '</div>'
            ].join('\n'),
            suggestion: function (data) {
                var metaDesc = '';
                if (data.MetaDescription.length > 80) {
                    metaDesc = data.MetaDescription.substring(0, 80) + '...';
                }
                else {
                    metaDesc = data.MetaDescription;
                }

                var pageTitle = data.Name;
                if (pageTitle == '') {
                    pageTitle = data.AltName;
                }
                return '<p><a href="' + data.Url + '"><span style=font-weight:bold;>' + pageTitle + '</span><br />' + metaDesc + '</a></p>';
            }
        }
    });
})($);
