// *****************************
// *****  Signpost Headings ****
// *****************************

var signpostHeadings = (function($) {

    var headings = [];

    var init = function() {
        // get any un-parsed headings and process them
        find();
        // calculate line styling
        format();
        // resize behaviour
        $(window).on('resize', function () {
            window.requestAnimationFrame(function() {
                format();
            });
        });
    };

    var find = function() {
        $('.signpost-heading:not(".signpost-heading-parsed")').each(function (i) {
            // wrap each word in a separate span and set as parsed
            var newCode = '<span class="first-line" aria-hidden="true"></span><span class="word">' + $(this).html().replace(/ +/g, '</span> <span class="word">') + '</span>';
            $(this).html(newCode).addClass('signpost-heading-parsed');
            // add to array
            headings.push({
                obj : $(this),
                spans : $(this).find('span.word'),
                first : $(this).find('span.first-line')
            });
            // add smaller class to long words
            $(headings[i].spans).each(function() {
                $(this).toggleClass('smaller', $(this).text().length > 13);
            });
        });
    };

    var format = function() {
        // clear existing settings
        $('.signpost-heading span.first-line').html('');
        $('.signpost-heading.signpost-heading-parsed span.word').removeClass('sr-only');
        // calculate new settings
        for(var i=0; i < headings.length; i++) {
            // loop through words and move top line into 'first-line' span
            var line = 0,
                copy = '',
                top = -1,
                smaller = false;
            $(headings[i].spans).each(function() {
                var getTop = Math.floor($(this).offset().top);
                if(getTop > top) {
                    top = getTop;
                    line++;
                }
                if(line === 1) {
                    copy += ' ' + $(this).html();
                    $(this).addClass('sr-only');
                    if($(this).hasClass('smaller')) smaller = true;
                }
            });
            // check for multi-word title all still on one line and, if found, force split first word
            if(line === 1 && headings[i].spans.length > 1) {
                copy = $(headings[i].spans[0]).html();
                for(var x = 1; x < headings[i].spans.length; x++) {
                    $(headings[i].spans[x]).removeClass('sr-only');
                }
            }
            // update 'first-line' span
            $(headings[i].first).html(copy).toggleClass('smaller', smaller);
        }
    };
    
    // public functions
    return {
        init: init,
        find: find,
        format: format
    };

})(jQuery);

$(document).ready(function () {
    signpostHeadings.init();
});