/* global siteSettings */

// ----------------------------------------------------------------------------------------------------
// Cookie Bar Engine v1.4
//
// ©2017 Semantic Ltd. All Rights Reserved.
// ----------------------------------------------------------------------------------------------------
/* exported  cookieBar */
var cookieBar = function (settings, $) {

    var cbBar = null,
        cbTimeout = null,
        hidden = false,
        cbActive = false;


    function triggerHiddenEvent() {
        hidden = true;
        $.event.trigger('cookieBar.hidden');
    }

    // Function to create a cookie
    function createCookie(name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toGMTString();
        } else expires = '';
        document.cookie = name + '=' + value + expires + '; path=/';
    }

    // Function to read a cookie
    function readCookie(name) {
        if (document.cookie) {
            var nameEQ = name + '=';
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        } else return null;
    }

    // Function to start/restart cookie bar timeout (15 seconds)
    var timeoutCookieBar = function () {
        if (cbTimeout) clearTimeout(cbTimeout);
        cbTimeout = setTimeout(hide, 10000);
    };

    // Function to slide invitation bar into view
    var show = function () {
        if ($) {

            // Create cookie bar element
            var content = '<div id="cookie-bar-area"><div id="cookie-bar"><div class="container"><div class="row">';
            content += '<div class="col-10"><p>' + settings.message + ' <a href="' + settings.linkUrl + '">' + settings.linkText + '</a></p></div>';
            content += '<div class="col-2"><p><button class="btn btn-minimal" type="button" aria-controls="cookie-bar" onclick="cookieBar.hide()"><i class="fa fa-times" aria-hidden="true"></i><span class="sr-only">' + siteSettings.translate.closeCookieBar + '</span></button></p></div>';
            content += '</div></div></div></div>';
            $('body').append(content);
            cbBar = $('#cookie-bar');
            cbBar.mousemove(timeoutCookieBar);

            // Calculate the right height
            var barHeight = $(cbBar).outerHeight();
            // Show the bar!
            cbBar.animate({ 'top': (0 - barHeight) }, 500);
            cbActive = true;

            // handle resize
            $(window).resize(function () {
                if (cbActive) {
                    barHeight = $(cbBar).outerHeight();
                    cbBar.stop().css({ 'top': (0 - barHeight) });
                }
            });

            // Start timeout to hide cookie bar again 
            timeoutCookieBar();

            // Set a cookie so the bar doesn't appear again for at least a year
            createCookie('dontshowCookieBar', '1', 365);
        }
    };

    // Function to slide cookie bar out of view
    var hide = function () {
        if ($) {
            // Hide the bar
            cbBar.animate({ 'top': '0' }, 300, function () {
                cbActive = false;
                $('#cookie-bar-area').remove();
                // launch booking widget
                //bookingWidget.init();
                triggerHiddenEvent();
            });
        }
    };

    // Show the cookie bar to users that haven't seen it yet
    if (readCookie('dontshowCookieBar') == null) {
        setTimeout(show(), 1000);
    } else {
        //bookingWidget.init();
        triggerHiddenEvent();
    }

    // public functions
    return {
        hide: hide,
        hidden: hidden
    };

}(siteSettings.cookieBar, jQuery);