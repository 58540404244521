
// ***************************
// ******  Skeletizer  *******
// ***************************

(function ($, w) {

    var t = [];
    var showSkeletonAt = 992;

    $('.tabs-wrapper').each(function (i, thisTabs) {
        t[i] = new Object({
            wrapper: thisTabs,
            index: i,
            tabs: $(this).find('ul.nav-tabs li.nav-item a'),
            skeletonMode: false,
            skeletonTimeout: null
        });
        // check for skeleton class
        t[i].skeletonMode = $(thisTabs).hasClass('with-tabs-skeleton');
        if (t[i].skeletonMode) {
            checkSkeleton(i);
            $(w).resize(function () {
                clearTimeout(t[i].skeletonTimeout);
                t[i].skeletonTimeout = setTimeout(checkSkeleton(i), 100);
            });
        }
    });

    function checkSkeleton(i) {
        var skeletonActive = false;
        if ($(w).width() > (showSkeletonAt - 1)) {
            var wrapperWidth = $(t[i].wrapper).outerWidth();
            var tabsWidth = 0;
            for (var x = 0; x < t[i].tabs.length; x++) {
                tabsWidth += $(t[i].tabs[x]).outerWidth();
            }
            if (((wrapperWidth - tabsWidth) / 2) >= 215) skeletonActive = true;
        }
        $(t[i].wrapper).toggleClass('skeleton-active', skeletonActive);
    }

})(jQuery, window);


// *****************************
// ****  Mobile Dropdown   *****
// *****************************

(function ($) {

    // Triger tab change when dropdown clicked.
    $('.tabs-mobile-nav').change(function () {
        $('#' + $(this).val()).tab('show');
    });

    // Update dropdown when tab shown by API
    $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
        $($(this).closest('.nav-tabs').data('mobile-nav')).val('tab-nav-' + this.href.split('#')[1]);
    });

})(jQuery);

// ************************************
// ****  Tab Navigation History   *****
// ************************************

(function ($, w, d) {

    // TODO - animate nicely...

    // tabs (as with all bootstrap bom shenanigans) is async so we need to emulate a callback by using a temporary event
    var scrollToHash = function () {
        $('html, body').animate({
            scrollTop: $(w.location.hash).offset().top
        }, 0);
        // We're done with this event handler
        $(this).off('shown.bs.tab', scrollToHash);
    };

    function showTab() {
        if (w.location.hash.length) {
            var $tab = $('a[href="' + w.location.hash + '"]');
            if (!$tab.hasClass('active')) {
                $tab.on('shown.bs.tab', scrollToHash);
                $tab.tab('show');
            }
        }
    }

    window.addEventListener('popstate', function () {
        showTab();
    });

    // No need to wait for dom ready 
    showTab();

    // Add tab changes as history events
    $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
        var stateObject = { 'url': $(this).attr('href') };
        // TODO - Do we need a plugin/polyfill? https://caniuse.com/#search=pushState
        if (w.location.hash && stateObject.url !== w.location.hash) {
            w.history.pushState(stateObject, d.title + ' - ' + this.innerText, w.location.pathname + $(this).attr('href'));
        } else {
            w.history.replaceState(stateObject, d.title + ' - ' + this.innerText, w.location.pathname + $(this).attr('href'));
        }
    });

})(jQuery, window, document);

