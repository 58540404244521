/* global globalSettings */
/* global Modernizr */
/* global siteSettings */

// ***************************
// *****  Scroll System  *****
// ***************************

/* exported scrollSystem */

var scrollSystem = (function($) {

    var prevScrollDistance = -1,
        scrollDistance = 0,
        scrollThrottle = false,
        scrollMax = 0,
        windowHeight = 0,
        windowInnerHeight = 0,
        headerStickyShowTimeout = null,
        headerStickyHideTimeout = null,
        headerHeight = 0,
        parallaxHero = {
            active : false
        };
    
    // init
    var init = function () {
        
        // add to top widget
        $('body').append('<div id="to-top-wrapper"><button id="to-top-button" class="btn btn-minimal" onclick="return scrollSystem.toTop()" title="' + siteSettings.translate.toTopButtonLabel + '"><i class="fa fa-angle-up" aria-hidden="true"></i><span>' + siteSettings.translate.toTopButtonText + '</span></button></div>');
        
        // parallax hero scene?
        if(Modernizr.csstransforms3d) {
            var findParallaxHero = $('#custom-feature-page--hero-wrapper.parallax');
            if(findParallaxHero.length > 0) {
                parallaxHero.active = true;
                parallaxHero.wrapper = findParallaxHero;
                parallaxHero.background = $(parallaxHero.wrapper).find('.parallax-background');
                parallaxHero.background_offset = 0;
                parallaxHero.midground = $(parallaxHero.wrapper).find('.parallax-midground');
                parallaxHero.midground_offset = 0;
                parallaxHero.foreground = $(parallaxHero.wrapper).find('.parallax-foreground');
            }
        }
        
        // scroll listener
        prevScrollDistance = window.pageYOffset;
        setInterval(function() {
            scrollDistance = window.pageYOffset;
            if(scrollDistance != prevScrollDistance) {
                update();
            }
            prevScrollDistance = scrollDistance;
        }, Math.floor(1000/60)); // 60 fps

        // run the update function
        update();       
    };
    
    // update
    var update = function () {
        windowHeight = $(window).height();
        headerHeight = globalSettings.breakpoint() === 'lg' || globalSettings.breakpoint() === 'xl' ? 80 : 70;
        windowInnerHeight = window.innerHeight ? window.innerHeight : windowHeight;
        scrollMax = $('body').height() - windowInnerHeight;
        // parallax hero?
        if(parallaxHero.active) {
            var heroHeight = $(parallaxHero.wrapper).height();
            var heroTop = $(parallaxHero.wrapper).offset().top;
            var y = scrollDistance - heroTop;
            if(y <= 0) {
                parallaxHero.background_offset = 0;
                parallaxHero.midground_offset = 0;
            } else if(y > 0 && y < heroHeight) {
                parallaxHero.background_offset = Math.floor(y * 0.66);
                parallaxHero.midground_offset = Math.floor(y * 0.33);
            }
        }
        // call for redraw using requestAnimationFrame
        if(!scrollThrottle) {
            scrollThrottle = true;
            window.requestAnimationFrame(scrollSystem.redraw);
        }
    };
    
    // redraw - apply changed settings to the DOM
    var redraw = function () {
        $('html').toggleClass('page-scrolled', scrollDistance >= 1);
        if(Modernizr.csstransforms3d) {
            if(scrollDistance > headerHeight && scrollDistance >= prevScrollDistance) {
                if(!headerStickyShowTimeout) {
                    clearTimeout(headerStickyHideTimeout);
                    headerStickyHideTimeout = null;
                    headerStickyShowTimeout = setTimeout(function() {
                        $('html').addClass('header-hidden');
                    },200);
                }
            } else if(scrollDistance > headerHeight && scrollDistance < scrollMax) {
                if(!headerStickyHideTimeout) {
                    clearTimeout(headerStickyShowTimeout);
                    headerStickyShowTimeout = null;
                    headerStickyHideTimeout = setTimeout(function() {
                        $('html').removeClass('header-hidden');
                    },200);
                }
            } else if(scrollDistance < scrollMax) {
                headerStickyHideTimeout = null;
                headerStickyShowTimeout = null;
                $('html').removeClass('header-hidden');
            }
            // parallax hero?
            if(parallaxHero.active) {
                $(parallaxHero.background).css({'transform' : 'translate3d(0,' + parallaxHero.background_offset + 'px,0)'});
                $(parallaxHero.midground).css({'transform' : 'translate3d(0,' + parallaxHero.midground_offset + 'px,0)'});
            }
        }
        // reset throttle
        scrollThrottle = false;
    };

    // toTop function
    var toTop = function() {
        $('html, body').animate({ scrollTop: scrollDistance }, 0).animate({ scrollTop: 0 }, 'fast', function() {
            scrollSystem.update();
        });
        return false;
    };

    // report windowInnerHeight
    var returnWindowInnerHeight = function() {
        return windowInnerHeight;
    };
    
    // public functions
    return {
        init: init,
        update: update,
        redraw : redraw,
        toTop : toTop,
        getWindowInnerHeight : returnWindowInnerHeight
    };

})(jQuery);
