// *************************************
// *****  Emergency Message System *****
// *************************************

(function ($) {

    var showMessage = true;

    if (sessionStorage) {
        if (sessionStorage.getItem('hide_emergency_message') != null) {
            if (sessionStorage.getItem('hide_emergency_message') == 'true') {
                showMessage = false;
            }
        }
    }
    if (showMessage) {
        $('#emergency-message--wrapper').addClass('on').attr('aria-hidden', false);
        $('#emergency-message--close-button').on('click', function (event) {
            event.preventDefault();
            sessionStorage.setItem('hide_emergency_message', 'true');
            $('#emergency-message--wrapper').attr('aria-hidden', true);
            $('#emergency-message').slideUp(500, function () {
                $('#emergency-message--wrapper').remove();
            });
        });
    } else {
        $('#emergency-message--wrapper').remove();
    }

})(jQuery);