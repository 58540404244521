/* global globalSettings */
/* global scrollSystem */
/* global siteSettings */

// *************************
// *****  Menu System  *****
// *************************

/* exported menuSystem */

var menuSystem = (function($) {

    var menuButtons = [],
        fullMenuSetup = false,
        mobileMenuSetup = false,
        mobileMenuActive = false,
        mobileSearchActive = false,
        megaMenuActive = false,
        menuHideDelay = null,
        activeMenuIndex = -1,
        menuRootFolder = '/';

    var init = function() {
        if (document.getElementById('main-menu')) {
            var pagePath = window.location.pathname;
            $('#main-menu .main-menu--button').each(function (i, button) {
                menuButtons[i] = {
                    hasSubmenu: $('#main-menu--submenu-' + i).length > 0 ? true : false,
                    submenuReady: false
                };
                if (menuButtons[i].hasSubmenu) {
                    $(button).addClass('with-submenu');
                    menuButtons[i].href = $('#main-menu--submenu-' + i + ' .main-menu--submenu-canonical a').attr('href');
                    menuButtons[i].target = $('#main-menu--submenu-' + i + ' .main-menu--submenu-canonical a').attr('target');
                } else {
                    menuButtons[i].href = $(button).attr('href');
                    menuButtons[i].target = $(button).attr('target');
                }
                // look for active menu area
                if(menuButtons[i].target == '_self' || menuButtons[i].target == '') {
                    var simpleHref = menuButtons[i].href;
                    if(simpleHref.indexOf(location.hostname) >= 0) {
                        simpleHref = simpleHref.split(location.hostname)[1];
                        if(simpleHref.indexOf(':') == 0) simpleHref = simpleHref.split(menuRootFolder)[1];
                    }
                    if((pagePath == menuRootFolder && simpleHref == menuRootFolder) || (simpleHref != menuRootFolder && pagePath.indexOf(simpleHref) == 0)) {
                        $(button).parent().addClass('menu-on');
                    }
                }
            });
            if (globalSettings.breakpoint() === 'lg' || globalSettings.breakpoint() === 'xl') {
                initFullNavigation();
            } else {
                initMobileNavigation();
            }
            // universal menu button triggers
            $('#main-menu .main-menu--button').each(function (i, button) {
                $(button).click(function() {
                    clickMenu(i);
                });
            });
            // escape button closes menus
            $(document).keyup(function(e) {
                if (e.keyCode == 27) {
                    if(menuSystem.isMobileMenuActive()) {
                        menuSystem.toggleMobileNavigation();
                    } else if(menuSystem.isMobileSearchActive()) {
                        menuSystem.toggleMobileSearch();
                    } else if(menuSystem.isMegaMenuActive()) {
                        menuSystem.hideMenu();
                    }
                } 
            });
        }
        // document touch (to cancel mega-menu)
        $('body').on('click touchstart', function (event) {
            var $target = $(event.target);
            if (globalSettings.breakpoint() === 'md' || globalSettings.breakpoint() === 'lg' || globalSettings.breakpoint() === 'xl') {
                if (megaMenuActive) {
                    if (!($target.is('#mega-menu') || $target.parents().is('#mega-menu') || $target.parents().is('#main-menu'))) {
                        event.preventDefault();
                        delayHideMenu();
                    }
                }
            }
        });
    };
    
    // set height
    var updateHeight = function() {
        if($('html').hasClass('mobile-navigation')) $('#menu-wrapper, #search-wrapper').css({'height' : scrollSystem.getWindowInnerHeight()});
    };
    
    // init mobile navigation
    var initMobileNavigation = function () {
        if(megaMenuActive) hideMenu();
        $('html').addClass('mobile-navigation');
        $('#main-menu').show();
        // hide sub-menus
        $('#main-menu .main-menu--button').each(function () {
            $(this).removeClass('open').attr('aria-expanded',false);
        });
        $('#main-menu .main-menu--submenu').each(function () {
            $(this).slideUp(0);
        });
        if (!mobileMenuSetup) {
            // menu switch set-up
            $('#mobile-menu-switch').on('click', function() {
                toggleMobileNavigation();
            });
            // menu close button
            $('#mobile-menu-close').on('click', function() {
                toggleMobileNavigation();
            });
            // search switch set-up
            $('#mobile-search-switch').on('click', function() {
                toggleMobileSearch();
            });
            // search close button
            $('#mobile-search-close').on('click', function() {
                toggleMobileSearch();
            });
            mobileMenuSetup = true;
        }
        // switches set-up
        $('#mobile-menu-switch, #mobile-search-switch').removeClass('on').attr('aria-expanded',false);
    };

    // toggle mobile navigation
    var toggleMobileNavigation = function () {
        if (mobileMenuActive) {
            $('html').removeClass('mobile-menu-active');
            $('#mobile-menu-switch').removeClass('on').attr('aria-expanded',false);
            mobileMenuActive = false;
        } else {
            if(mobileSearchActive) toggleMobileSearch();
            $('html').addClass('mobile-menu-active');
            $('#mobile-menu-switch').addClass('on').attr('aria-expanded',true);
            mobileMenuActive = true;
            updateHeight();
        }
    };
    
    // toggle mobile search
    var toggleMobileSearch = function () {
        if (mobileSearchActive) {
            $('html').removeClass('mobile-search-active');
            $('#mobile-search-switch').removeClass('on').attr('aria-expanded',false);
            mobileSearchActive = false;
        } else {
            if(mobileMenuActive) toggleMobileNavigation();
            $('html').addClass('mobile-search-active');
            $('#mobile-search-switch').addClass('on').attr('aria-expanded',true);
            mobileSearchActive = true;
            updateHeight();
        }
    };


    // init full navigation
    var initFullNavigation = function () {
        $('html').removeClass('mobile-navigation');
        // clear heights
        $('#menu-wrapper, #search-wrapper').removeAttr('style');
        if (!fullMenuSetup) {
            // create mega-menu if it doesn't exist
            if (!document.getElementById('mega-menu--wrapper')) {
                $('#header-outer-wrapper').append('<div id="mega-menu--wrapper"><div id="mega-menu"><div id="mega-menu--content"></div></div></div>');
                $('#mega-menu--wrapper').hide();
                /* $('#mega-menu--wrapper').on('mouseover', function() {
                    showMenu(activeMenuIndex, false);
                }).on('mouseleave', function() {
                    delayHideMenu();
                }); */
            }
            // build submenus
            for(var i=0; i< menuButtons.length; i++) {
                if (menuButtons[i].hasSubmenu) {
                    // create submenu content if it doesn't exist
                    if (!menuButtons[i].submenuReady) {
                        var thisSubmenu = $('#main-menu--submenu-' + i);
                        var columnBreak = false;
                        // canonical link with image?
                        var firstLink = $(thisSubmenu).find('.main-menu--submenu-canonical');
                        var firstLinkAnchor = $(firstLink).find('a');
                        var imageData = $(firstLink).data('image');
                        var imageWidth = (globalSettings.breakpoint() === 'lg' == 'xl' ? 216 : 262) * (globalSettings.hires() ? 2 : 1);
                        var buildContent = '<navigation id="mega-menu--content-' + i + '" class="container" aria-labelledby="main-menu--button-' + i + '">';
                        // canonical link
                        buildContent += '<div class="row"><div class="col-7 offset-4 mega-menu--canonical-link"><a href="' + $(firstLinkAnchor).attr('href') + '" target="' + $(firstLinkAnchor).attr('target') + '"><span>' + $(firstLinkAnchor).html() + '</span></a></div></div>';
                        buildContent += '<div class="row"><div class="col-4" aria-hidden="true">';
                        if (typeof imageData != 'undefined') {
                            // with image
                            buildContent += '<a class="mega-menu--canonical-image" tabindex="-1" href="' + $(firstLinkAnchor).attr('href') + '" target="' + $(firstLinkAnchor).attr('target') + '">';
                            buildContent += '<img class="img-fluid" src="' + imageData + '?width=' + imageWidth + '&quality=70" alt="' + $(firstLinkAnchor).text() + '" /></a>';
                        }
                        buildContent += '</div><div class="col-4">';
                        $(thisSubmenu).find('.main-menu--submenu-section').each(function(x, thisSection) {
                            if(x > 0 && !columnBreak && $(this).hasClass('starts-new-column')) {
                                buildContent += '</div><div class="col-4">';
                                columnBreak = true; // make sure we only break once per menu area
                            }
                            buildContent += '<div class="column-container">' + $(thisSection).html() + '</div>';
                        });
                        buildContent += '</div>';
                        buildContent += '<button class="mega-menu--close-button btn btn-minimal" type="button" aria-controls="mega-menu" onclick="menuSystem.hideMenu()"><i class="fa fa-times" aria-hidden="true"></i><span class="sr-only">' + siteSettings.translate.hideMenu + '</span></button>';
                        buildContent += '</div>';
                        buildContent += '</navigation>';
                        $('#mega-menu--content').append(buildContent);
                        menuButtons[i].submenuReady = true;
                    }
                }
            }
            // add search results container
            $('#mega-menu--content').append('<div id="quick-search-results-wrapper"></div>');
            $('#mega-menu #quick-search-results-wrapper').hide();
            // finish up
            $('#mega-menu .container').hide();
            fullMenuSetup = true;
        }
        // reset mobile active states
        $('html').removeClass('mobile-menu-active');
        $('#menu-launcher a').removeClass('on');
        mobileMenuActive = false;
    };
    
    // click menu
    var clickMenu = function (menuIndex) {
        if (mobileMenuActive) {
            if (menuButtons[menuIndex].hasSubmenu) {
                $('#main-menu--submenu-' + menuIndex).slideToggle('fast');
                $('#main-menu--button-' + menuIndex).toggleClass('open').attr('aria-expanded', $(this).hasClass('open'));
            }
        } else {
            if (menuButtons[menuIndex].hasSubmenu) {
                if (megaMenuActive && menuIndex === activeMenuIndex) {
                    hideMenu();
                } else {
                    showMenu(menuIndex, true);
                }
            } else {
                if (megaMenuActive) hideMenu();
            }
        }
    };
    
    // show menu
    var showMenu = function (menuIndex, fromClick) {
        if (!mobileMenuActive) {
            clearTimeout(menuHideDelay);
            // turn menu class to 'hover'
            $('#menu' + menuIndex).addClass('menu-hover').attr('aria-expanded', true);
            // show submenu?
            if (menuIndex >= 0 && activeMenuIndex !== menuIndex) {
                // hide old menu
                if (activeMenuIndex >= 0) $('#menu' + activeMenuIndex).removeClass('menu-hover').attr('aria-expanded', false);
                $('#mega-menu .container').hide();
                // show new menu
                activeMenuIndex = menuIndex;
                if (menuButtons[menuIndex].hasSubmenu) {
                    // display menu
                    if (megaMenuActive) {
                        // set correct submenu content
                        $('#mega-menu--content-' + menuIndex).show();
                        // show
                        $('#mega-menu--wrapper').show();
                    } else {
                        $('#mega-menu').stop(true, true).css({ 'top': -1000 });
                        $('#mega-menu--content-' + menuIndex).show();
                        $('#mega-menu--wrapper').show();
                        var menuHeight = $('#mega-menu--wrapper').height();
                        $('#mega-menu').css({ 'top': (0 - menuHeight) });
                        // reveal
                        $('#mega-menu').animate({ 'top': 0 }, 300);
                        megaMenuActive = true;
                    }
                    // focus on first link if triggered by click (not mouseover)
                    if(fromClick) $('#mega-menu--content-' + menuIndex + ' a')[0].focus();
                }
            }
        }
    };
    
    // delay hide menu
    var delayHideMenu = function () {
        if (!mobileMenuActive && activeMenuIndex >= 0) {
            // turn off menu 'on' class
            if (megaMenuActive) {
                clearTimeout(menuHideDelay);
                menuHideDelay = setTimeout(function() {
                    hideMenu();
                }, 500);
            }
        }
    };

    // hide menu
    var hideMenu = function () {
        if (activeMenuIndex >= 0) {
            $('#menu' + activeMenuIndex).removeClass('menu-hover').attr('aria-expanded', false);
            activeMenuIndex = -1;
            // slide menu up
            if (megaMenuActive) {
                var menuHeight = $('#mega-menu--wrapper').height();
                $('#mega-menu').animate({ 'top': (0 - menuHeight) }, 200, 'swing', function () {
                    $('#mega-menu .columns-container').hide();
                    $('#mega-menu--wrapper').hide();
                });
            }
        }
        clearTimeout(menuHideDelay);
        menuHideDelay = null;
        megaMenuActive = false;
    };

    var mobileMenuActiveState = function() {
        return mobileMenuActive;
    };
    var megaMenuActiveState = function() {
        return megaMenuActive;
    };
    var mobileSearchActiveState = function() {
        return mobileSearchActive;
    };
    
    // public
    return {
        init: init,
        initMobileNavigation: initMobileNavigation,
        initFullNavigation: initFullNavigation,
        isMobileMenuActive: mobileMenuActiveState,
        isMobileSearchActive: mobileSearchActiveState,
        isMegaMenuActive: megaMenuActiveState,
        toggleMobileSearch: toggleMobileSearch,
        toggleMobileNavigation: toggleMobileNavigation,
        clickMenu: clickMenu,
        showMenu: showMenu,
        delayHideMenu: delayHideMenu,
        hideMenu: hideMenu,
        updateHeight: updateHeight
    };



})(jQuery);