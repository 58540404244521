/* global cookieBar */

// ****************************
// *****  Booking Widget  *****
// ****************************

/* exported  bookingWidget */
/* global cookieBar */
var bookingWidget = (function($,d) {

    var viewed = false,
        topStart = 0,
        widget = {};

    var init = function () {
        if(!viewed && $('#booking-widget').length) {
            var showWidget = true;
            if (sessionStorage) {
                if(sessionStorage['hideBookingWidget']) showWidget = false;
            }
            if (showWidget) {
                // set up
                topStart = $('html').hasClass('svg') ? 20 : 0;
                widget.wrapper = $('#booking-widget--wrapper');
                widget.obj = $('#booking-widget');
                // reveal
                widget.height = $('#booking-widget').outerHeight();
                $(widget.obj).animate({ 'top': (0 - widget.height) }, 500);
                viewed = true;
                // handle resize
                $(window).resize(function () {
                    var newHeight = $('#booking-widget').outerHeight();
                    if(newHeight != widget.height) {
                        widget.height = newHeight;
                        $(widget.obj).stop().css({ 'top': (0 - widget.height) });
                    }
                });
            }
        }
    };

    var hide = function() {
        $(widget.obj).animate({ 'top': topStart }, 300, function () {
            if(sessionStorage) {
                sessionStorage.setItem('hideBookingWidget', 'true');
            }
            $(widget.wrapper).remove();
        });
    };

    // Maybe cookie bar has already done it's thing, maybe it hasn't
    if (cookieBar && cookieBar.hidden) {
        init();
    }
    else
    {
        $(d).on('cookieBar.hidden', init);
    }

    // public functions
    return {
        hide: hide
    };

})(jQuery, document);