
// *************************************
// *****  Welcome Modal  ***************
// *************************************

/* exported  welcomeModal */

var welcomeModal = (function ($) {

    var stopWelcomeCookieName = 'DUN_stop_welcome_popup';
    var $welcomePopup = $('#welcome-modal');

    var settings = {
        debug: false,
        daysToDisablePopup: 60
    };

    logDebugMessage('Welcome modal initializing..');

    function init() {
        var welcomeModalBlocked = jQuery.cookie(stopWelcomeCookieName) !== undefined;

        if (welcomeModalBlocked) {
            logDebugMessage('cookie is set to block.. return');

            return;
        }

        if (!$welcomePopup.length) {
            logDebugMessage('Popup doesnt exist.. return.');

            return;
        }

        if (!welcomeModalBlocked) {
            setTimeout(function () {
                loadWelcomeModal();
            }, 600);
        }
    }
    function setStopWelcomeModalCookie() {
        jQuery.cookie(stopWelcomeCookieName, true, { expires: settings.daysToDisablePopup });

        logDebugMessage('Stopped this popup nonsense..');
    }

    function loadWelcomeModal() {
        $('#welcome-modal').modal('show');
        $('#welcome-modal').on('hidden.bs.modal', function() {
            setStopWelcomeModalCookie();
            logDebugMessage('disabled popup..');
        });

    }

    function logDebugMessage(message) {
        if (settings.debug) {
            console.log(message);
        }
    }

    return {
        init: init,
        stopPopup: setStopWelcomeModalCookie
    };
})(jQuery);