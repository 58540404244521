/* global globalSettings */

// ***************************************************************
// ************* Top Info Bar - crossfade on mobiles *************
// ***************************************************************

/* exported topInfoBar */

var topInfoBar = (function ($) {

    var ib = {
        index: 0,
        resizeTimeout: null,
        timer: null,
        delay: 5000,
        bp: '',
        paused: false,
        initMinHeight: 20
    };

    var init = function() {
        ib.wrapper = $('#top-info-banner');
        ib.row = $(ib.wrapper).find('.row')[0];
        $(ib.row).removeClass('sr-only');
        ib.messages = $(ib.wrapper).find('.info-item');
        if(ib.messages.length > 1) {
            reset();
            $(window).on('resize', function() {
                clearTimeout(ib.resizeTimeout);
                ib.resizeTimeout = setTimeout('topInfoBar.reset();',100);
            });
        }
        $(ib.wrapper).on('mouseover focusin', function() {
            ib.paused = true;
        }).on('mouseout focusout', function() {
            ib.paused = false;
        });
    };

    var reset = function() {
        var get_bp = globalSettings.breakpoint();
        // check heights
        if (get_bp === 'xs' || get_bp === 'sm') {
            var minHeight = ib.initMinHeight;
            $(ib.row).css({'min-height' : ib.initMinHeight + 'px'});
            for(var x = 0 ; x < ib.messages.length; x++) {
                var h = $(ib.messages[x]).outerHeight();
                if(h > minHeight) minHeight = h;
            }
            $(ib.row).css({'min-height' : minHeight + 'px'});
            for(x = 0 ; x < ib.messages.length; x++) {
                h = $(ib.messages[x]).outerHeight();
                var t = h < minHeight ? (minHeight-h)/2 : 0;
                $(ib.messages[x]).css({'top' : t + 'px'});
            }
        }
        if(get_bp != ib.bp) {
            clearTimeout(ib.timer);
            ib.timer = null;
            ib.bp = get_bp;
            if (ib.bp === 'xs' || ib.bp === 'sm') {
                // restart
                $(ib.wrapper).addClass('top-info-banner-fader');
                for(x = 0 ; x < ib.messages.length; x++) {
                    if(x === ib.index) {
                        $(ib.messages[x]).fadeOut(0).fadeIn(500);
                    } else {
                        $(ib.messages[x]).fadeOut(0);
                    }
                }
                ib.timer = setTimeout(function() {
                    update();
                }, ib.delay);
            } else {
                // turn off
                $(ib.wrapper).removeClass('top-info-banner-fader');
                $(ib.row).css({'min-height' : '0'});
                $(ib.wrapper).find('.info-item').stop().show().css({'top' : t + '0'});
            }
        }
    };

    var update = function() {
        var offscreen = ($(ib.wrapper).offset().top + $(ib.wrapper).outerHeight()) < window.pageYOffset;
        if(offscreen || ib.paused) {
            ib.timer = setTimeout(function() {
                update();
            }, ib.delay);
        } else {
            $(ib.messages[ib.index]).fadeOut(200, function() {
                ib.index++;
                if(ib.index >= ib.messages.length) ib.index = 0;
                $(ib.messages[ib.index]).fadeOut(0).fadeIn(500);
                ib.timer = setTimeout(function() {
                    update();
                }, ib.delay);
            });
        }
    };

    // public
    return {
        init: init,
        reset: reset
    };

})(jQuery);