/* global siteSettings */

// *****************************
// *****  Countdown Widget *****
// *****************************

(function ($) {

    var c = [];

    $('.countdown-widget').each(function(i, countdown) {
        c[i] = {
            wrapper : countdown,
            getDeadline : $(this).data('deadline'),
            getEndMessage : $(this).data('end-message')
        };
        // check for valid deadline date
        if(Date.parse(c[i].getDeadline)) {
            c[i].deadline = new Date(c[i].getDeadline).getTime();
            // add container to dom
            $(c[i].wrapper).html('<div id="countdown-' + i + '" class="countdown wiggly-edge"><div class="countdown-inner"></div><div class="countdown-sr sr-only" role="alert" aria-live="off"></div></div>');
            c[i].obj = $('#countdown-' + i + ' .countdown-inner');
            c[i].sr = $('#countdown-' + i + ' .countdown-sr');
            // Update the count down every second
            c[i].interval = setInterval(function(x) {
                // Get todays date and time
                var now = new Date().getTime();
                var distance = c[x].deadline - now;
                if(distance > 0) {
                    // Time calculations for days, hours, minutes and seconds
                    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    var writeDays = (days.toString().length < 2 ? '0' : '') + days;
                    var writeHours = (hours.toString().length < 2 ? '0' : '') + hours;
                    var writeMinutes = (minutes.toString().length < 2 ? '0' : '') + minutes;
                    var writeSeconds = (seconds.toString().length < 2 ? '0' : '') + seconds;
                    var daysLabel = days == 1 ? siteSettings.translate.day : siteSettings.translate.days;
                    var hoursLabel = hours == 1 ? siteSettings.translate.hour : siteSettings.translate.hours;
                    var minutesLabel = minutes == 1 ? siteSettings.translate.minute : siteSettings.translate.minutes;
                    var code = '<span class="countdown-days"><strong>' + writeDays + '</strong> <span class="countdown-label">' + siteSettings.translate.days + '</span></span> ';
                    code += '<span class="countdown-hours"><strong>' + writeHours + '</strong> <span class="countdown-label">' + siteSettings.translate.hours + '</span></span> ';
                    code += '<span class="countdown-minutes"><strong>' + writeMinutes + '</strong> <span class="countdown-label">' + siteSettings.translate.minutes + '</span></span> ';
                    code += '<span class="countdown-seconds"><strong>' + writeSeconds + '</strong> <span class="countdown-label">' + siteSettings.translate.seconds + '</span></span>';
                    $(c[x].obj).html(code);
                    $(c[x].wrapper).toggleClass('countdown-pulse', seconds == 59);
                    if(seconds == 0) {
                        $(c[x].sr).html(siteSettings.translate.countdownLabel + ' : ' + days + ' ' + daysLabel + ', ' + hours + ' ' + hoursLabel + ', ' + minutes + ' ' + minutesLabel + '.').attr('aria-live','assertive');
                    }
                } else {
                    clearInterval(c[x].interval);
                    if(c[x].getEndMessage != '') {
                        // show message
                        $(c[x].obj).html('<strong>' + c[x].getEndMessage + '</strong>');
                        $(c[x].sr).html(siteSettings.translate.countdownLabel + ' : ' + c[x].getEndMessage).attr('aria-live','assertive');
                    } else {
                        // show zeros
                        code = '<span class="countdown-days"><strong>00</strong> <span class="countdown-label">' + siteSettings.translate.days + '</span></span> ';
                        code += '<span class="countdown-hours"><strong>00</strong> <span class="countdown-label">' + siteSettings.translate.hours + '</span></span> ';
                        code += '<span class="countdown-minutes"><strong>00</strong> <span class="countdown-label">' + siteSettings.translate.minutes + '</span></span> ';
                        code += '<span class="countdown-seconds"><strong>00</strong> <span class="countdown-label">' + siteSettings.translate.seconds + '</span></span>';
                        $(c[x].obj).html(code);
                        $(c[x].sr).html(siteSettings.translate.countdownLabel + ' : 0 ' + siteSettings.translate.days + ', 0 ' + siteSettings.translate.hours + ', 0 ' + siteSettings.translate.minutes + '.').attr('aria-live','assertive');
                    }
                }
            }, 1000, i);
        }
    });

})(jQuery);