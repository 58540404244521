/* global menuSystem */
/* global scrollSystem */
/* global carouselList */
/* global topInfoBar */

// **********************************
// *****  Global site settings  *****
// **********************************

var globalSettings = (function($) {

    var breakpoint = 'xs',
        oldBreakpoint = '',
        hires = false;

    var update = function() {
        // store last breakpoint
        oldBreakpoint = breakpoint;
        // get breakpont
        breakpoint = window.getComputedStyle(document.body, ':after').getPropertyValue('content');
        if (breakpoint === '') {
            breakpoint = $('#MQ').css('font-family');
        }
        breakpoint = breakpoint.replace(/['"]+/g, '');
        // retina graphics?
        if (window.devicePixelRatio) {
            if (window.devicePixelRatio >= 1.5) {
                hires = true;
                $('html').toggleClass('hires', hires);
            }
        }
    };

    var init = function () {

        // Everything here will get run on "ready", synchronously, and will block render
        // Avoid adding anything here unless absolutely necessary (and even then check with Jason that it _is_ necessary first)

        update();
        // set up scroll system
        scrollSystem.init();
        // set up menus
        menuSystem.init();
        // other engines
        carouselList.init();
        // top info bar
        if($('#top-info-banner').length > 0) topInfoBar.init();
        // resize
        $(window).on('resize', function() {
            update();
            // change menu systems?
            if(oldBreakpoint != breakpoint) {
                if((breakpoint === 'lg' || breakpoint === 'xl') && (oldBreakpoint !== 'lg' && oldBreakpoint !== 'xl')) {
                    menuSystem.initFullNavigation();
                } else if((breakpoint !== 'lg' && breakpoint !== 'xl') && (oldBreakpoint === 'lg' || oldBreakpoint === 'xl')) {
                    menuSystem.initMobileNavigation();
                }
            } else if($('html').hasClass('mobile-navigation')) {
                menuSystem.updateHeight();
            }
        });
        // preload some core img/svg css hover resources
        var preloads = {
            files : [
                '/theme/img/svg/buttons/button-square-bg--snot-green.svg',
                '/theme/img/svg/buttons/button-wide-bg--snot-green.svg',
                '/theme/img/svg/menu/mega-menu-bottom-tile.svg'
            ],
            loads : []
        };
        for (var x=0 ; x < preloads.files.length; x++) {
            preloads.loads[x] = new Image();
            preloads.loads[x].src = preloads.files[x];
        }
    };

    var reportBreakpoint = function() {
        return breakpoint;
    };
    
    var reportOldBreakpoint = function() {
        return oldBreakpoint;
    };

    var reportHires = function() {
        return hires;
    };
    
    // public functions
    return {
        init: init,
        update: update,
        breakpoint: reportBreakpoint,
        oldBreakpoint: reportOldBreakpoint,
        hires: reportHires
    };

})(jQuery);

$(document).ready(function () {
    globalSettings.init();
});